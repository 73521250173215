import { css  } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 56],
    background: 'transparent',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    //height: [44, null, 56],
    background: variables.darkBrownColor,
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [80, null, 120],
    marginTop: ['65px', null, '100px' ],
    transition: 'all 0.3s ease-in-out'
  })),
  logoScrolled: css(mq({
    //height: [22, null, 36],
    //marginTop : '0px'
    img : mq({
      height: [37, null, 50],
      marginTop : '0px'
    })
  })),
}