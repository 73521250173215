const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"laca-text",sans-serif',
  familyHeader: '"adobe-handwriting-ernie",serif',

  primaryColor: '#736357',
  lightBrownColor : '#c7b299',
  darkBrownColor : '#736357',
  fontColor: '#736357',
  bgColor: 'white',

  ...overloading
}

export default variables;