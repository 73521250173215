
// import { renderRule as code_subscribe } from './code-subscribe/render';
// import { renderRule as html_v1 } from './html-v1/render';
// import { renderRule as panel_v1 } from './panel-v1/render';
// import { renderRule as videoPanel } from './videoPanel/render';
export const items = [
  // code_subscribe,
  // html_v1,
  // panel_v1,
  // videoPanel,
]